<!-- Created by henian.xu on 2018/6/1. -->

<template>
  <!--v-if="+data.orderStatus===10"-->
  <div class="refund-status">
    <div class="inner">
      <!-- 售后状态：1:待审核; 2:待打款; 3:已退款; 4:已关闭; 5:已取消; 6:待退货; 7:待收货 -->
      <i
        class="f-icon fs-big"
        v-if="+data.refundStatus===1"
      >&#xf050;</i>
      <i
        class="f-icon fs-big"
        v-else-if="+data.refundStatus===2"
      >&#xf051;</i>
      <i
        class="f-icon fs-big"
        v-else-if="+data.refundStatus===3"
      >&#xf052;</i>
      <i
        class="f-icon fs-big"
        v-else-if="+data.refundStatus===4"
      >&#xf054;</i>
      <i
        class="f-icon fs-big"
        v-else-if="+data.refundStatus===5"
      >&#xf053;</i>
      {{ data.refundStatusName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RefundStatus',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.refund-status {
  padding: $padding $padding 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-size: 0.36rem;
  > .inner {
    background-color: $color-second;
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: $padding $padding-big;
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
    min-height: 1rem;
    > .f-icon {
      margin-right: $margin-small;
      line-height: 1;
    }
  }
}
</style>
